import axios from "axios";
import { apiUrl } from "../constants/config";

const api = axios.create({
    baseURL: apiUrl,
    timeout: 5000,
    headers: {}
})

export const httpOptions = {
  baseURL: apiUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

export default api;