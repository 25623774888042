import api from "../../utils/api";

const Theses = {
  namespaced: true,
  state: () => ({
    theses: [],
    lastThese: {},
  }),
  mutations: {
    setTheses(state, payload) {
      state.theses = payload;
    },
  },
  actions: {
    fetchThesesForApplication({ commit }, payload) {
      return api
        .get(`/applications/${payload.application_id}/theses`)
        .then((res) => {
          if (res.status == 200) {
            commit("setTheses", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};

export default Theses;
