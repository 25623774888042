<template>
  <nav
    v-if="!inPreview && !inIFrame"
    id="nav-bar"
    class="flex flex-row justify-content-between"
  >
    <div class="flex flex-row mt-1 mb-1">
      <a href="/" style="display: flex; align-items: center;">
        <img id="nav-bar-logo" style="content: var(--p-logo-url)" alt="Logo" />
      </a>
      <p v-if="applicationName.length > 0" class="align-self-end nav-bar-title">
        {{ applicationName }}
      </p>
    </div>
    <!-- Mobile -->
    <PButton
      id="mobile-menu-button"
      @click="onToggleDrawer()"
      class="p-button-text"
      icon=" pi pi-bars"
    />
    <!-- Dektop -->
    <PButton
      id="desktop-menu-button"
      @click="onToggleDrawer()"
      :label="$t('menu.menu')"
      class="p-button-text"
      icon=" pi pi-bars"
      iconPos="right"
    />
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { localeOptions } from "../../constants/config";
import { getDirection, setDirection } from "../../utils";

export default {
  name: "Topnav",
  emits: ["toggleDrawer"],
  data() {
    return {
      applicationName: "",
      localeOptions,
    };
  },
  mounted() {
    if (
      (this.$route.path === "/theses" || this.$route.path === "/matches") &&
      localStorage.getItem("activeApplication")
    ) {
      if (!this.inIFrame) {
        let activeApp = JSON.parse(localStorage.getItem("activeApplication"));
        if (activeApp !== null) {
          this.applicationName = activeApp.title;
        }
      } else {
        this.applicationName = this.activeApplication.title;
      }
    }
  },
  methods: {
    onToggleDrawer() {
      this.$emit("toggleDrawer");
    },
    changeLocale(locale, direction) {
      const currentDirection = getDirection().direction;
      if (direction !== currentDirection) {
        setDirection(direction);
      }
      this.setLang(locale);
    },
  },
  computed: {
    ...mapGetters({ activeApplication: "apps/getActiveApplication" }),
    ...mapState(["inIFrame"]),
    ...mapState(["inPreview"]),
  },
  watch: {
    $route(to) {
      if (to.path === "/theses" || to.path === "/matches") {
        if (!this.inIFrame) {
          if (localStorage.getItem("activeApplication")) {
            this.applicationName = JSON.parse(
              localStorage.getItem("activeApplication")
            ).title;
          } else {
            if (this.activeApplication !== null) {
              this.applicationName = this.activeApplication.title;
            }
          }
        } else {
          // Fix for dark mode
          document.body.style.backgroundColor = "white";
          if (this.activeApplication !== null) {
            this.applicationName = this.activeApplication.title;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#nav-bar {
  background-color: #fafafa;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 0.2rem;
  position: relative;
  z-index: 2000;
}

#nav-bar-logo {
  max-height: 25px;
  margin-left: .5rem;
}

@media screen and (min-width: 720px) {
  #nav-bar-logo {
    max-height: 40px;
    margin-left: 2rem;
  }
}

.nav-bar-title {
  margin: 0 0;
  margin-left: .5rem;
}
#desktop-menu-button {
  display: none;
}

#mobile-menu-button {
  display: inline-flex;
}

@media screen and (min-width: 720px) {
  .nav-bar-title {
    margin: 0 0;
    margin-left: 2rem;
  }
  #desktop-menu-button {
    display: inline-flex;
  }
  #mobile-menu-button {
    display: none;
  }
}
</style>
