
export const apiKey = "AIzaSyALj-DWPg2UbWZ6x1JBvBAlpbaUO6A7u_E";

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// PROD CONFIG
export const firebaseConfig = {
  apiKey: "AIzaSyALj-DWPg2UbWZ6x1JBvBAlpbaUO6A7u_E",
  authDomain: "votoprod.firebaseapp.com",
  projectId: "votoprod",
  storageBucket: "votoprod.appspot.com",
  messagingSenderId: "831446817065",
  appId: "1:831446817065:web:7e736aac0261fb6ac63f38",
  measurementId: "G-3DG4FGK5N7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export {
    app, db, storage
}