import api from "../../utils/api";

const Votes = {
  namespaced: true,
  state: () => ({
    userVotes: [],
  }),
  mutations: {
    setUserVotes(state, payload) {
      state.userVotes = payload;
    },
  },
  getters: {
    getUserVotes: (state) => state.userVotes,
  },
  actions: {
    submitVotes(_, votes) {
      return api
        .post(`/vote`, votes)
        .then((res) => {
          if (res.status == 201) {
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
  }
};

export default Votes;
