import Apps from "./modules/apps.js";
import { createStore } from "vuex";
import Elections from "./modules/elections.js";
import Theses from "./modules/theses.js";
import Matches from "./modules/matches.js";
import Votes from "./modules/votes.js";
import Timeline from "./modules/timeline.js";

export default createStore({
  modules: {
    apps: Apps,
    elections: Elections,
    theses: Theses,
    matches: Matches,
    votes: Votes,
    timeline: Timeline,
  },
  state: () => ({
    inIFrame: false,
    inPreview: false,
  }),
  mutations: {
    setInIFrame(state, payload) {
      state.inIFrame = payload;
    },
    setInPreview(state, payload) {
      state.inPreview = payload;
    },
  },
  getters: {
    getInIFrame: (state) => state.inIFrame,
  },
  actions: {
    checkInIFrame() {
      if (window.location !== window.parent.location) {
        this.commit("setInIFrame", true)
      }
    },
  },
});
