
const Apps = {
  namespaced: true,
  state: () => ({
    activeApplication: null,
    config: {},
  }),
  getters: {
   getActiveApplication: state => state.activeApplication,
   getAppConfig: state => state.config
   
  },
  mutations: {
    setActiveApplication(state, payload) {
      state.activeApplication = payload;
    },
    setAppConfig(state,payload) {
      state.config = payload;
    }
  },
  actions: {

  },
};

export default Apps;
