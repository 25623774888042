<script>
import api from "../utils/api";

export default {
  methods: {
    async fetchAvailableLanguages(application_id) {
      const response = await api.get(
        `/applications/${application_id}/theses/languages`
      );
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
  },
};
</script>
