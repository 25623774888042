import api from "../../utils/api";

const Timeline = {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
  },
  actions: {
    async fetchTimeline({ commit }) {
      const result = await api.get(`/timeline`, {timeout: 60000});
      if (result.status == 200) {
        commit("setItems", result.data);
        return result.data;
      }
      return [];
    },
  },
};

export default Timeline;
