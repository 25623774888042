import api from "../../utils/api";

const Elections = {
  namespaced: true,
  state: () => ({
    elections: [],
    election: {},
    election_apps: [],
  }),
  mutations: {
    setElections(state, payload) {
      state.elections = payload;
    },
    setElection(state, payload) {
      state.election = payload;
    },
    setApps(state, payload) {
      state.election_apps = payload;
    },
  },
  getters: {
    getElectionById: (state) => (id) => {
      return state.elections.find((e) => e.id == id);
    },
  },
  actions: {
    fetchElections({ commit }) {
      return api
        .get(`/elections`)
        .then((res) => {
          if (res.status == 200) {
            commit("setElections", res.data.data);
            return res.data.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    fetchAllElections({ commit }) {
      return api
        .get(`/elections?pagination=false`)
        .then((res) => {
          if (res.status == 200) {
            commit("setElections", res.data.data);
            return res.data.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    fetchElectionById({ commit }, election_id) {
      return api
        .get(`/elections/${election_id}`)
        .then((res) => {
          if (res.status == 200) {
            commit("setElection", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    async fetchApplicationsForElection({ commit }, election_id) {
      return  api
        .get(`/elections/${election_id}/applications?limit=100`)
        .then((res) => {
          if (res.status == 200) {
            commit("setApps", res.data.data);
            return res.data.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};

export default Elections;
