import api from "../../utils/api";

const Matches = {
  namespaced: true,
  state: () => ({
    candidates: [],
    candidateMatches: [],
    candidatesLight: [],
    parties: [],
    partyMatches: [],
    currentCandidateMatch: null,
    currentPartyMatch: null,
  }),
  mutations: {
    setCandidates(state, payload) {
      state.candidates = payload;
    },
    setCandidateMatches(state, payload) {
      state.candidateMatches = payload;
    },
    setCandidatesLight(state, payload) {
      state.candidatesLight = payload;
    },
    setParties(state, payload) {
      state.parties = payload;
    },
    setPartyMatches(state, payload) {
      state.partyMatches = payload;
    },
    setCurrentCandidateMatch(state, payload) {
      state.currentCandidateMatch = payload;
    },
    setCurrentPartyMatch(state, payload) {
      state.currentPartyMatch = payload;
    },
  },
  actions: {
    fetchCandidates({ commit }, appID) {
      return api
        .get(`/applications/${appID}/matches/candidates`)
        .then((res) => {
          if (res.status == 200) {
            commit("setCandidates", res.data.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    fetchCandidatesForParty({ commit }, payload) {
      return api
        .get(`/applications/${payload.appID}/matches/parties/${payload.partyID}/candidates`)
        .then((res) => {
          if (res.status == 200) {
            commit("setCandidates", res.data.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    fetchCandidatesLight({ commit }, payload) {
      return api
        .get(
          `/applications/${payload.appID}/matches/parties/${payload.partyID}/candidates-light`
        )
        .then((res) => {
          if (res.status === 200) {
            commit("setCandidatesLight", res.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    fetchParties({ commit }, appID) {
      return api
        .get(`/applications/${appID}/matches/parties`)
        .then((res) => {
          if (res.status == 200) {
            commit("setParties", res.data.data);
            return res.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  getters: {
    getCandidates: (state) => state.candidates,
    getCandidateMatches: (state) => state.candidateMatches,
    getParties: (state) => state.parties,
    getPartyMatches: (state) => state.partyMatches,
    getCurrentCandidateMatch: (state) => state.currentCandidateMatch,
    getCurrentPartyMatch: (state) => state.currentPartyMatch,
  },
};

export default Matches;
