export const apiUrl = "https://api.voto.vote";
// export const apiUrl = 'http://127.0.0.1:3000';

export const defaultLocale = 'ro'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'de', name: 'Deutsch', direction: 'ltr' },
  { id: 'de_easy', name: 'Einfache Sprache', direction: 'ltr' },
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ro', name: 'Română', direction: 'ltr' }

]

export const AppEvent = {
    VOTO_Started: 0,
    VOTO_Finished: 1,
  };

export const MatchType = {
    CandidateMatch: 0,
    PartyMatch: 1,
    HybridMatch: 2,
    BinaryMatch: 3
  };
  